<template>
  <b-card-code title="Ajouter un certificat de garantie">
    <b-card-text>
      <span>saisir les informations du certificat de garantie</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Numéro facture"
            >
              <validation-provider
                #default="{ errors }"
                name="invoice_number"
              >
                <b-form-input
                  v-model="guaranteeCertificate.invoice_number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Numéro facture"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="date"
              label-for="date"
            >
              <flat-pickr
                v-model="guaranteeCertificate.date"
                class="form-control invoice-edit-input"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Client"
            >
              <validation-provider
                #default="{ errors }"
                name="Client"
              >
                <v-select
                  v-model="guaranteeCertificate.customer"
                  :options="customers"
                  :clearable="false"
                  label="company_name"
                  :reduce="customer => customer.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Produits"
            >
              <validation-provider
                #default="{ errors }"
                name="products"
              >
                <v-select
                  v-model="guaranteeCertificate.products"
                  :options="products"
                  :clearable="false"
                  label="name"
                  :reduce="product => product.id"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addGuaranteeCertificate"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      guaranteeCertificate: {
        invoice_number: '',
        customer: '',
        products: '',
        date: '',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('customer', ['customers']),
    ...mapGetters('product', ['products']),
  },
  created() {
    this.getCustomers()
    this.getProducts()
  },
  methods: {
    ...mapActions('customer', ['getCustomers']),
    ...mapActions('product', ['getProducts']),
    async addGuaranteeCertificate() {
      try {
        await axiosIns.post('/guarantee-certificates/create/', this.guaranteeCertificate)
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Certificat de garantie ajouté avec succès')
        }, 1000)
        this.$router.push({ name: 'guarantee-certificates' })
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
